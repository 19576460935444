import * as React from "react"
import "./../style/common.scss"
import { CommonLayout } from "../common/common-layout/common-layout"
import { MediaPage } from "../page-components/media/media-page/media-page"

export default function Media(props) {
  return (
    <CommonLayout {...props}>
      <MediaPage/>
    </CommonLayout>
  )
}
