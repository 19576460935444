import * as React from "react"
import * as css from "./media-page.module.scss"
import { MediaList } from "../media-list/media-list"
import { PrEmail } from "../../../constants/social-network-url"
import { Helmet } from "react-helmet"
import { useTranslation } from "../../../common/use-translations/use-translations"

export function MediaPage() {
  const _ = useTranslation()
  return (
    <>
      <Helmet>
        <title>{_.we_at_media} - OBRIO</title>
      </Helmet>
      <section className={css.section}>
        <div className="container">
          <div className={css.heading}>
            <p>
              {_.want_to_contact}
              {_.contact_us}: <a href={"milto:" + PrEmail}>{PrEmail}</a>
            </p>
          </div>
          <MediaList />
        </div>
      </section>
    </>
  )
}
