import * as React from "react"
import * as css from "./media-filters.module.scss"
import { MediaFilterCheckboxChangedEvent, MediaFiltersCheckbox } from "./media-filters-checkbox"
import { useEffect, useState } from "react"
import { useTranslation } from "../../../common/use-translations/use-translations"

export interface MediaFilterOption {
  value: string;
  label: string;
}

interface MediaFiltersProps {
  options: MediaFilterOption[];
  onChanged?: (options: string[]) => void;
}

export function MediaFilters(props: MediaFiltersProps) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const numSelectedOptions = selectedOptions.length;
  const [reset, setReset] = useState(false);
  const _ = useTranslation();

  useEffect(() => {
    if (reset) {
      setTimeout(() => {
        setReset(false);
      }, 100);
    }
  }, [reset]);

  const handleFiltersChange = (event: MediaFilterCheckboxChangedEvent) => {
    let updatedOptions;

    if (event.isChecked) {
      updatedOptions = [...selectedOptions, event.value];
    } else {
      updatedOptions = selectedOptions.filter((option) => option !== event.value);
    }

    setSelectedOptions(updatedOptions);
    props.onChanged && props.onChanged(updatedOptions);
  };

  const handleClearButtonClick = () => {
    setSelectedOptions([]);
    setReset(true);
    props.onChanged && props.onChanged([]);
  };

  return (
    <div className={css.filters}>
      {props.options.map((option, index) => (
        <MediaFiltersCheckbox
          key={index}
          value={option.value}
          label={option.label}
          onChanged={handleFiltersChange}
          reset={reset}
        />
      ))}
      {numSelectedOptions > 1 && (
        <button className={css.clearButton} onClick={handleClearButtonClick}>
          {_.clear}
        </button>
      )}
    </div>
  );
}