import * as React from 'react';
import * as css from './media-filters.module.scss';
import classNames from "classnames";
import { useEffect, useState } from "react";

export interface MediaFilterCheckboxChangedEvent {
  value: string;
  isChecked: boolean;
}

interface MediaFilterCheckboxProps {
  value: string;
  label: string;
  isChecked?: boolean;
  onChanged?: (event: MediaFilterCheckboxChangedEvent) => void;
  reset: boolean;
}

export function MediaFiltersCheckbox(props: MediaFilterCheckboxProps) {
  const [isChecked, setChecked] = useState(props.isChecked);

  const handleClick = () => {
    setChecked(!isChecked);
    props.onChanged && props.onChanged({value: props.value, isChecked: !isChecked});
  }

  useEffect(() => {
    setChecked(props.isChecked);
  }, [props.isChecked]);

  useEffect(() => {
    if (props.reset) {
      setChecked(false);
      props.onChanged && props.onChanged({ value: props.value, isChecked: false });
    }
  }, [props.reset]);

  return (
    <label className={classNames(css.mediaFiltersCheckbox, isChecked ? css.mediaFiltersCheckboxActive : '')}
           onClick={handleClick}>
      <span className={css.mediaFiltersCheckbox__label}>{ props.label }</span>
    </label>
  )
}