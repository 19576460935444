import { MediaSource } from "./media-source"

export const mediaSourceInfoList: MediaSource[] = [
  {
    slug: "tc",
    label: "TechCrunch",
    author: "alex_fedorov",
    position: "СЕО",
    text: "How to effectively manage a remote team during wartime. 6 tips from the CEO of a Ukrainian startup",
    url: "https://techcrunch.com/2022/10/31/how-to-effectively-manage-a-remote-team-during-wartime/",
  },
  {
    slug: "speka",
    label: "SPEKA",
    author: "natalia_elenivska",
    position: "2D Artist",
    text: "poverbanki_dlya_zsu",
    url: "https://speka.media/obrio/poverbanki-dlya-zsu-ta-dopomoga-usijeyu-simjeyu-yak-dopomagayut-armiyi-faxivci-z-it-kompaniyi-obrio-9x18wv",
  },
  {
    slug: "laba",
    label: "Laba",
    author: "OBRIO",
    position: "",
    text: "kompanii_s_chetkoy_poziciey",
    url: "https://l-a-b-a.com/uk/blog/3068-krizis-perezhivut-kompanii-s-chetkoy-poziciey-otnositelno-voyny",
  },
  {
    slug: "dou",
    label: "DOU",
    author: "solomia_dmitruk",
    position: "",
    text: "Як знайти роботу під час війни: 13 відео, щоб отримати офер",
    url: "https://dou.ua/forums/topic/39241/",
  },
  {
    slug: "dou",
    label: "DOU",
    author: "OBRIO",
    position: "",
    text: "Як IT-компанії змінили підходи до Performance Review з початком війни",
    url: "https://dou.ua/lenta/articles/performance-review-during-the-war/?from=recent",
  },
  {
    slug: "youtube",
    label: "YouTube",
    author: "anna_khoma",
    position: "",
    text: "Вебінар «Soft чи Hard skills — які навички потрібні, аби стати топовим маркетологом»",
    url: "https://www.youtube.com/watch?v=f2rIMRu2epE&t=3s",
  },
  {
    slug: "prjctr",
    label: "Projector",
    author: "solomia_dmitruk",
    position: "",
    text: "Як створити вдалий профіль в LinkedIn",
    url: "https://prjctr.com/library/video/linkedin",
  },
  {
    slug: "vector",
    label: "Vector",
    author: "OBRIO",
    position: "",
    text: "obrio_pid_chas_vijny",
    url: "https://vctr.media/ua/pidtrymka-yak-kompaniya-obrio-praczyuye-pid-chas-vijny-134086/",
  },
  {
    slug: "dou",
    label: "DOU",
    author: "OBRIO",
    position: "",
    text: "help_ukraine_media",
    url: "https://dou.ua/lenta/articles/help-the-army/",
  },
  {
    slug: "vector",
    label: "Vector",
    author: "alex_fedorov",
    position: "СЕО",
    text: "alex_fedorov_media",
    url: "https://vctr.media/ua/13-mln-zavantazhen-ta-100-ekspertiv-pidsumki-2021-vid-obrio-114679/",
  },
  {
    slug: "dou",
    label: "DOU",
    author: "anna_khoma",
    position: "CMO",
    text: "anna_khoma_media_2",
    url: "https://ain.ua/2022/01/26/marketyng-na-riznyx-etapax-rozvytku-obrio/",
  },
  {
    slug: "dou",
    label: "DOU",
    author: "alex_fedorov",
    position: "CEO",
    text: "alex_fedorov_media_2",
    url: "https://dou.ua/lenta/articles/results-of-the-2021/?from=tge&utm_source=telegram&utm_medium=social",
  },
  {
    slug: "dou",
    label: "DOU",
    author: "andrew_bodnar",
    position: "iOS Developer",
    text: "andrew_bodnar_media",
    url: "https://dou.ua/forums/topic/35564/",
  },
  {
    slug: "vector",
    label: "Vector",
    author: "maria_yovsa",
    position: "HR Business Partner",
    text: "maria_yovsa_media",
    url: "https://vctr.media/ua/10-efektivnih-instrumentiv-dlya-rozvitku-komandi-107117/",
  },
  {
    slug: "mc-today",
    label: "MC.today",
    author: "maks_lukominskii",
    position: "Head of Web",
    text: "maks_lukominskii_media",
    url: "https://mc.today/blogs/chetyre-printsipa-raboty-kotorye-pomogayut-stroit-silnye-komandy/",
  },
  {
    slug: "ain",
    label: "ain",
    author: "kate_petuhova",
    position: "Маркетолог",
    text: "kate_petuhova_media",
    url: "https://ain.ua/2021/10/27/kejs-nebula/",
  },
  {
    slug: "forbes",
    label: "Forbes",
    author: "alex_fedorov",
    position: "СЕО",
    text: "alex_fedorov_media_3",
    url: "https://forbes.ua/leadership/sistema-tsilepokladannya-okr-zrobila-google-vidatnim-yak-ii-adaptuvati-pid-sviy-biznes-05082021-2212",
  },
  {
    slug: "dou",
    label: "DOU",
    author: "solomia_dmitruk",
    position: "Talent Acquisition Manager",
    text: "solomia_dmitruk_media",
    url: "https://dou.ua/forums/topic/34748/?from=comment-digest_post&utm_source=transactional&utm_medium=email&utm_campaign=digest-comments",
  },
  {
    slug: "dou",
    label: "DOU",
    author: "nikita_machehin",
    position: "Backend developer",
    text: "nikita_machehin_media",
    url: "https://dou.ua/forums/topic/34491/?from=company_posts",
  },
  {
    slug: "zt",
    label: "zaichenko.team",
    author: "alina_neverova",
    position: "Recruitment Lead",
    text: "alina_neverova_media",
    url: "https://zaichenkoteam.com/shho-pochytaty-podyvytys-posluhaty-rekruteram-ta-hr/",
  },
  {
    slug: "dou",
    label: "DOU",
    author: "alex_rumantsev",
    position: "Node.js Developer",
    text: "alex_rumantsev_media",
    url: "https://dou.ua/forums/topic/33744/?from=company_posts",
  },
  {
    slug: "sho-tam",
    label: "#ШОТАМ",
    author: "anna_khoma",
    position: "Head of Marketing",
    text: "anna_khoma_media",
    url: "https://shotam.info/ne-tilky-dlia-pidlitkiv-iak-sotsialna-merezha-tiktok-dopomozhe-vashomu-biznesu/",
  },
  {
    slug: "babel",
    label: "Бабель",
    author: "alex_fedorov",
    position: "CEO",
    text: "alex_fedorov_media_4",
    url: "https://babel.ua/texts/59452-rozrobniki-z-kiyeva-dva-roki-rozvivayut-astrologichniy-dodatok-nebula-natalnaya-karta-v-telefoni-astrologi-frilanseri-ta-chayovi-vid-vdyachnih-kliyentiv-tak-cifroviy-svit-uzhivayetsya-iz-zirkami",
  },
  {
    slug: "tc",
    label: "TechCrunch",
    author: "alex_lychak",
    position: "Head of Product",
    text: "5 product management tips that can help startups thrive in 2023",
    url: "https://techcrunch.com/2023/02/17/5-product-management-tips-that-can-help-startups-thrive-in-2023/",
  },
  {
    slug: "hackernoon",
    label: "Hackernoon",
    author: "alex_fedorov",
    position: "CEO",
    text: "How to Run a Tech Business in 2023: 4 Tips From a Ukrainian Startup CEO",
    url: "https://hackernoon.com/how-to-run-a-tech-business-in-2023-4-tips-from-a-ukrainian-startup-ceo",
  },
  {
    slug: "recursive",
    label: "Recursive",
    author: "alex_fedorov",
    position: "CEO",
    text: "From War-Torn Country to Tech Hub: How Ukraine Is Redefining Its Global Image",
    url: "https://therecursive.com/from-war-torn-country-to-tech-hub-how-ukraine-is-redefining-its-global-image/",
  },
  {
    slug: "village",
    label: "The Village Україна",
    author: "alex_fedorov",
    position: "CEO",
    text: "13 млн завантажень і 100 експертів. Підсумки-2021 від OBRIO",
    url: "https://www.the-village.com.ua/village/city/city-report/341173-yak-vigotovlyayut-mobilni-lazni-y-pralni-dlya-ukrayinskih-viyskovih-pobuvali-na-virobnitstvi",
  },
  {
    slug: "epravda",
    label: "Економічна правда",
    author: "OBRIO",
    position: "",
    text: "Ринок астрологічних застосунків – 2 мільярди доларів і росте як на дріжджах.",
    url: "https://www.epravda.com.ua/publications/2023/07/13/702192/",
  },
  {
    slug: "entrepreneur",
    label: "Entrepreneur",
    author: "khrystyna_savchuk",
    position: "PR Manager",
    text: "Distinguishing Brands in the Market",
    url: "https://www.entrepreneur.com/business-news/how-does-storytelling-impact-a-startups-brand-identity/469276",
  },
  {
    slug: "tnw",
    label: "TheNextWeb",
    author: "",
    position: "",
    text: "How the Ukrainian startup behind astrology app Nebula is thriving despite the war",
    url: "https://thenextweb.com/news/ukrainian-startup-astrology-app-nebula-thriving-despite-war",
  },
  {
    slug: "tech_eu",
    label: "Tech.eu",
    author: "alex_fedorov",
    position: "CEO",
    text: "How Ukrainian startup OBRIO turned psychic reading and astrology into a thriving business venture",
    url: "https://tech.eu/2023/12/05/obrio/",
  },
  {
    slug: "vector",
    label: "Vector",
    author: "alex_fedorov",
    position: "CEO",
    text: "Командні гравці, принципи та шлях до єдинорога",
    url: "https://vctr.media/ua/komandni-gravczi-princzipi-ta-shlyah-do-yedinoroga-yak-vi-praczyuyete-oleksandr-fedorov-ceo-obrio-204328/",
  },
  {
    slug: "vector",
    label: "Vector",
    author: "khrystyna_savchuk",
    position: "PR Manager",
    text: "Як підготувати спікера до виступу на IT Arena? 7 порад для комунікаційників",
    url: "https://vctr.media/ua/yak-pidgotuvati-spikera-do-vistupu-na-it-arena-7-porad-dlya-komunikaczijnikiv-vid-pr-menedzhera-obrio-200766/",
  },
]

export const mediaSourceInfoListEn: MediaSource[] = [
  {
    slug: "tc",
    label: "TechCrunch",
    author: "alex_fedorov",
    position: "СЕО",
    text: "How to effectively manage a remote team during wartime. 6 tips from the CEO of a Ukrainian startup",
    url: "https://techcrunch.com/2022/10/31/how-to-effectively-manage-a-remote-team-during-wartime/",
  },
  {
    slug: "nyt",
    label: "The New York Times",
    author: "",
    position: "",
    text: "The New York Times mentioned our flagship product Nebula",
    longText: "“Ilios is part of a constellation of popular apps, including Co-Star, Sanctuary, the Pattern and Nebula, that aim to illuminate interpersonal connections using astrological ...",
    url: "https://www.nytimes.com/2022/05/23/style/astrology-dating-app-ilios.html",
  },
  {
    slug: "beebom",
    label: "BeeBom",
    author: "",
    position: "",
    text: "17 Best Free Horoscope Apps for Android and iPhone",
    longText: "“Well-known for offering accurate zodiac predictions, Nebula has got everything covered to be your one-stop platform for horoscope and astrology...",
    url: "https://beebom.com/free-horoscope-apps/",
  },
  {
    slug: "urban-list",
    label: "Urban List",
    author: "",
    position: "",
    text: "The Best Astrology Apps You Need In Your Life Right Now",
    longText: "“If you're after a bit of education and enlightenment with your horoscopes, Nebula is the astrology app for you. With a simple setup and sleek interface, checking your daily ...",
    url: "https://www.theurbanlist.com/a-list/best-astrology-apps",
  },
  {
    slug: "wellgood",
    label: "Well + Good",
    author: "",
    position: "",
    text: "The 5 Best Horoscope Apps for Your Daily Dose of Cosmic Guidance",
    longText: "“Nebula is maybe the prettiest astrology app I've used so far...Great for: Those who want to invest in an astrological experience that ticks off all the boxes, and looks literally ...",
    url: "https://www.wellandgood.com/best-horoscope-apps/",
  },
  {
    slug: "hackernoon",
    label: "Hackernoon",
    author: "alex_fedorov",
    position: "CEO",
    text: "How to Run a Tech Business in 2023: 4 Tips From a Ukrainian Startup CEO",
    url: "https://hackernoon.com/how-to-run-a-tech-business-in-2023-4-tips-from-a-ukrainian-startup-ceo",
  },
  {
    slug: "entrepreneur",
    label: "Entrepreneur",
    author: "khrystyna_savchuk",
    position: "PR Manager",
    text: "Distinguishing Brands in the Market",
    url: "https://www.entrepreneur.com/business-news/how-does-storytelling-impact-a-startups-brand-identity/469276",
  },
  {
    slug: "tnw",
    label: "TheNextWeb",
    author: "",
    position: "",
    text: "How the Ukrainian startup behind astrology app Nebula is thriving despite the war",
    url: "https://thenextweb.com/news/ukrainian-startup-astrology-app-nebula-thriving-despite-war",
  },
  {
    slug: "tech_eu",
    label: "Tech.eu",
    author: "alex_fedorov",
    position: "CEO",
    text: "How Ukrainian startup OBRIO turned psychic reading and astrology into a thriving business venture",
    url: "https://tech.eu/2023/12/05/obrio/",
  },
]
