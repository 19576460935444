import * as css from "./media-list.module.scss"
import * as React from "react"
import { MediaSource } from "./media-source"
import { useTranslation } from "../../../common/use-translations/use-translations"

export function MediaListItem({ media }: { media: MediaSource }) {
  const _ = useTranslation()
  return (
    <div className={css.card}>
      <div className={css.top}>
        <img src={`/media/${media.slug}.svg`} alt="" />
      </div>
      <div className={css.bottom}>
        <div className={css.author}>
          {_[media.author] && <div className={css.name}>{_[media.author] || media.author}</div>}
          {media.position && <div className={css.position}>{media.position}</div>}
        </div>

        <p className={css.text}>{_[media.text] || media.text}</p>
        {media.longText && <p className={css.longText}>{media.longText}</p>}
        <a href={media.url} target="_blank">{_.read_more}</a>
      </div>
    </div>
  )
}
