import * as React from "react"
import * as css from "./media-list.module.scss"
import { MediaListItem } from "./media-list-item"
import {
  mediaSourceInfoList,
  mediaSourceInfoListEn,
} from "./media-source-info-list"
import { useLocale } from "../../../common/use-locale/use-locale"
import { MediaFilters } from "../media-filters/media-filters"
import { useState } from "react"

export function MediaList() {
  const { locale } = useLocale()
  const [selectedFilters, setSelectedFilters] = useState([])
  console.log(mediaSourceInfoList, mediaSourceInfoListEn);

  const mediaList =
    locale === "en" ? mediaSourceInfoListEn : mediaSourceInfoList

  const newMediaPublicationsList = React.useMemo(() => mediaList.reverse(), [mediaList]);

  const filterOptions = mediaList
    .reduce(
      (acc, current) =>
        acc.some(option => option.value === current.slug)
          ? acc
          : [...acc, { value: current.slug, label: current.label }],
      []
    )

  const handleFiltersChange = (filters: string[]): void => {
    setSelectedFilters(filters)
  }

  return (
    <>
      <MediaFilters options={filterOptions} onChanged={handleFiltersChange} />
      <div className={css.grid}>
        {newMediaPublicationsList
          .filter(
            media =>
              !selectedFilters.length || selectedFilters.includes(media.slug)
          )
          .map((media, index) => (
            <MediaListItem key={index} media={media} />
          ))}
      </div>
    </>
  )
}
